<template>
    <div style="width: 100%; min-height: 100vh; background: rgb(237, 241, 255);">
        <van-nav-bar
            title="新增波币钱包地址"
            left-arrow
            fixed
            :style="{ backgroundColor: 'rgb(237, 242, 253)' }"
            @click-left="onClickLeft"
        />
        <div style="height: 46px;"></div>
        <div class="tipsh">
            <div class="tops">
                波币钱包 流通性高 不受监管
                <span @click="learnMore">了解更多 &gt;</span>
            </div>
            <div class="tsg">
                <div class="tsgs">绑定协议地址</div>
                <div class="tsgs">交易划转</div>
                <div class="tsgs">完成取款</div>
            </div>
        </div>
        <div class="usrse">

            <div class="hgs">
                <div class="nams">波币钱包地址</div>
                <div style="border-bottom: 1px solid rgb(242, 242, 242);">
                    <van-field v-model="formData.bank_no" placeholder="请输入波币地址" />
                </div>
            </div>
            <div class="hgs">
                <div class="nams">支付密码</div>
                <div style="border-bottom: 1px solid rgb(242, 242, 242);">
                    <van-field v-model="formData.pay_pass" type="password" placeholder="请输入支付密码" />
                </div>
            </div>
            <van-button type="info" block style="margin-top: 20px;" @click="confirmAdd">确认添加</van-button>
        </div>
    </div>
</template>

<script>
import { NavBar, Field, Button } from 'vant';

export default {
    components: {
        [NavBar.name]: NavBar,
        [Field.name]: Field,
        [Button.name]: Button,
    },
    data() {
        return {
            formData: {
                card_type:6,
                bank:'bbpay',
                bank_no: "",
                pay_pass:"",

            }
        };
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1);
        },
        learnMore() {
            // Implement the logic to show more information about USDT
            console.log('Learn more about qn');
        },
        selectProtocol(protocol) {
            this.formData.bank_owner = protocol;
        },
        confirmAdd() {
            let that = this;
            if (!that.formData.bank_no) {
                that.$parent.showTost(0, '请输入波币钱包地址');
                return;
            }
            if (!that.formData.pay_pass) {
                that.$parent.showTost(0, '请输入支付密码');
                return;
            }
            that.$parent.showLoading();
            that.$apiFun
                .post('/api/bindcard', that.formData)
                .then(res => {
                    if (res.code != 200) {
                        that.$parent.showTost(0, res.message);
                    }
                    if (res.code == 200) {
                        that.$parent.showTost(1, '成功');
                        that.$router.back();
                    }
                    that.$parent.hideLoading();
                })
                .catch(res => {
                    that.$parent.hideLoading();
                });
        },

    },
};
</script>

<style scoped>
.tipsh {
    width: 95%;
    margin: 6px auto;
    border-radius: 10px;
    background: #f8f8f8;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 6px
}

.tipsh .tops {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: .4rem;
    font-weight: 700;
    color: #333;
    height: 1rem
}

.tipsh .tops span {
    font-size: .29rem;
    font-weight: 400
}

.tipsh .tsg {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.tipsh .tsg .tsgs {
    height: .56rem;
    line-height: .56rem;
    color: #a5a9b3;
    font-size: .2rem;
    text-align: center;
    padding: 4px 8px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-image: url(data:image/png;
    base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAAA0CAYAAADPCHf8AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALCSURBVHgB7d0hcNtAEIXhtWh4y8vDixseXHPz4ibYKW2Kw1UsHt7g8vCWB7u7mYsnss7S3elmam3+b8bjxM6Evaz1tFJWu91uKyLnAixbt1qt7qSyRh+3Aizfpf6xv5TKGk3dX31uBVi+jYak6qehJjx3+ngSYPmuNCTvpZLngOgUsXBU//wG/Adn+thqSM6kgpcJYiG516ffAizfO31cSQXNwfc/BfDhXKfIRmbqBUSniE2QewF8sGZrLTM0kdfsWIQDdnix1pB8lEKDgIQD9k4AP75oSD5IgdgEsZDYeZE/AvhgjVZR/duMvPdDAD+s2fqaW/8eDUg4YKf2hSf2MSur2Wom3mdPC95c5DRbowFhTwtOWbN1kfKDUxPEsKcFjzYpzdZkQNjTglNJzVbKBGFPC15NNltJAQnY04JHo81WckDY04JjR5utnAli2NOCV+vYJbtZAWFPC84Nmq3cCWIsIOxpwavt62YrOyBhirCnBa96l+yWTBD2tODd/pLdlRTShNkv4QQiPOuKJgjwRjzNCcisa32BE/dgFw4WBSRsQiZtQwILZFvs3+2L0gnyWQCfLBzXoa3ND0g4JV/t1o7AibnRcOzP82UFJDRX1e+gDZyIOw3H4+sXcieITY8q9zwFTkyr4RisUSUHJEwPDszh0UO41dVAzgTZCuDPvrGKSQpIqHU5MIc3vcYqZjIg4aMVtS68sVD0GquYlAlirRXTA960h41VzGhAqHXhVLSxipmaIOxbwZvuWGMVczQg7FvBocfc/6U+NkE4MIcn1lh9k0zRgLBvBWessbqeaqxiBgEJB+afBPDjtiQcJjZBmB7wxBqrX1KoFxD2reBMVmMVczhB2LeCF9mNVcw+IOxbwZGixirmOSDsW8GR4sYq5mWCMD3gxU2tcJgmTA9WSuBBG+76WY1NEMIBD9q5jVXMPzyG0oj5jr9QAAAAAElFTkSuQmCC);background-size: cover
}

.tsg {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tsgs {
    height: 0.56rem;
    line-height: 0.56rem;
    color: #a5a9b3;
    font-size: 0.2rem;
    text-align: center;
    padding: 4px 8px;
    flex: 1;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAAA0CAYAAADPCHf8AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALCSURBVHgB7d0hcNtAEIXhtWh4y8vDixseXHPz4ibYKW2Kw1UsHt7g8vCWB7u7mYsnss7S3elmam3+b8bjxM6Evaz1tFJWu91uKyLnAixbt1qt7qSyRh+3Aizfpf6xv5TKGk3dX31uBVi+jYak6qehJjx3+ngSYPmuNCTvpZLngOgUsXBU//wG/Adn+thqSM6kgpcJYiG516ffAizfO31cSQXNwfc/BfDhXKfIRmbqBUSniE2QewF8sGZrLTM0kdfsWIQDdnix1pB8lEKDgIQD9k4AP75oSD5IgdgEsZDYeZE/AvhgjVZR/duMvPdDAD+s2fqaW/8eDUg4YKf2hSf2MSur2Wom3mdPC95c5DRbowFhTwtOWbN1kfKDUxPEsKcFjzYpzdZkQNjTglNJzVbKBGFPC15NNltJAQnY04JHo81WckDY04JjR5utnAli2NOCV+vYJbtZAWFPC84Nmq3cCWIsIOxpwavt62YrOyBhirCnBa96l+yWTBD2tODd/pLdlRTShNkv4QQiPOuKJgjwRjzNCcisa32BE/dgFw4WBSRsQiZtQwILZFvs3+2L0gnyWQCfLBzXoa3ND0g4JV/t1o7AibnRcOzP82UFJDRX1e+gDZyIOw3H4+sXcieITY8q9zwFTkyr4RisUSUHJEwPDszh0UO41dVAzgTZCuDPvrGKSQpIqHU5MIc3vcYqZjIg4aMVtS68sVD0GquYlAlirRXTA960h41VzGhAqHXhVLSxipmaIOxbwZvuWGMVczQg7FvBocfc/6U+NkE4MIcn1lh9k0zRgLBvBWessbqeaqxiBgEJB+afBPDjtiQcJjZBmB7wxBqrX1KoFxD2reBMVmMVczhB2LeCF9mNVcw+IOxbwZGixirmOSDsW8GR4sYq5mWCMD3gxU2tcJgmTA9WSuBBG+76WY1NEMIBD9q5jVXMPzyG0oj5jr9QAAAAAElFTkSuQmCC);
    background-size: cover;
}

.usrse {
    background: #fff;
    box-sizing: border-box;
    padding: 20px;
}

.nams {
    font-size: 0.38rem;
    color: #000;
    vertical-align: middle;
    font-weight: 700;
}



* , .van-tab__text, .van-tab__text--ellipsis{
    font-size: 12px;
}
:deep(.van-field__body){
    font-size: 12px;
}
:deep(.van-button--normal),:deep(.van-button__text){
    font-size: 12px !important;
}

.boxst {
    width: 90%;
    background: #fff;
    border-radius: 20px;
    position: relative;
    margin: -10px auto 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 20px
}


.adds .btntits {
    text-align: center;
    margin-top: 20px;
    font-size: .28rem;
    color: #6c6e82
}
.usrse {
    background: #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 20px
}

.usrse .nams {
    font-size: .38rem;
    color: #000;
    vertical-align: middle;
    margin-top: 10px;
    font-weight: 700
}

.usrse .imgsa {
    position: relative;
    height: 2rem;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: .2rem
}

.usrse .imgsa .bisn {
    width: .8rem;
    position: absolute;
    bottom: .3rem;
    left: 1.4rem
}

.usrse .imgsa img {
    width: 2rem;
    border-radius: 50%
}[class*=van-hairline]:after {
     border: none
 }

.sc {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 20px
}

.sc .ssa {
    border-radius: 5px;
    border: 1px solid #f1f1f1;
    width: 2rem;
    height: 1rem;
    line-height: 1rem;
    font-size: .4rem;
    font-weight: 700;
    text-align: center;
    margin-left: 1rem
}

.sc .acti {
    color: #597ef7!important;
    border: 1px solid #597ef7!important
}


</style>
