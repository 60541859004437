<template>
    <div>
        <div style="height: 180px;"></div>
        <img class="bancgs" src="/static/image/bank_020021515.png" alt="" @click="$router.back()">
        <div class="topsf">
            <div class="tois">
                <img src="/static/style/tx.0d38194b71b5a32ef1df50b7090ca7f5.png" alt="">
                <div class="dwd">
                    <div class="tisaa">Hi,尊敬的会员用户</div>
                    <div class="newsa">早上好，欢迎来到帮助中心</div>
                </div>
            </div>
            <div style="color: #fff; text-align: center; margin-top: 20px;">若相关问题仍未解决，可咨询在线客服</div>
        </div>
        <div class="bosfs">
            <div v-for="(item, index) in menuItems" :key="index" class="hgsw" :style="index === menuItems.length - 1 ? {border: 'none'} : {}" @click="$parent.goNav(`/boutBallBetInfo?type=${item.type}`)">
                <img class="firsimg" src="/static/image/1587555761884253.png" alt="">
                <span class="tit">{{ item.title }}</span>
                <span class="tisf"></span>
                <img class="rigiong" src="/static/style/icoArrowGrey@2x.abc43ab099390c4587d33290a76fc15e.png" alt="">
            </div>
        </div>
        <div style="margin-top: 0.48rem; text-align: center; color: #6c7c9d; padding-bottom: 0.6rem;">
            没有找到解决办法？请联系<a style="color: #597ef7; font-weight: 600;" @click="$router.push('/kefu')">人工客服</a>解决
        </div>
    </div>
</template>

<script>
export default {
    name: 'BoutBallBet',
    data() {
        return {
            menuItems: [
                { title: '关于我们', type: 1 },
                { title: '常见问题', type: 2 },
                { title: '隐私政策', type: 3 },
                { title: '免责说明', type: 4 },
                { title: '联系我们', type: 5 },
                { title: '代理加盟', type: 6 },
                { title: '博彩责任', type: 7 }
            ]
        }
    }
}
</script>

<style scoped>
*{
    font-size:12px
}
/* Add any additional styles as needed */
.bancgs {
    position: fixed;
    top: 10px;
    left: 10px;
    width: 30px;
    opacity: .8;
    z-index: 200
}

p {
    -webkit-margin-before: 5px!important;
    margin-block-start: 5px!important;
    -webkit-margin-after: 5px!important;
    margin-block-end: 5px!important
}

.inputsw {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 999;
    cursor: pointer
}

.van-tab--active {
    color: #108ee9
}

.van-tabs__line {
    background-color: #108ee9
}

.van-cell {
    padding: 5px 8px
}

.sdg .van-field__label {
    width: .2rem
}[class*=van-hairline]:after {
     border: none
 }

.sdgg .van-popup {
    border-radius: 15px 15px 0 0
}

.stddss .van-field__control {
    font-size: .5rem
}

.van-tabbar-item__icon img {
    display: block;
    height: 34px
}

.van-tabbar-item {
    font-size: 14px
}

.van-tabbar {
    height: 68px
}

.van-tabbar-item--active {
    background-color: #f3f6ff
}
.bsd {
    width: 90%;
    background: #fdfdfd;
    border-radius: 10px;
    padding: 20px 10px;
    margin: 10px auto 0
}

.topsf {
    background: url(/static/image/welcome-bg.812e6eebb547ed38a04db1056d489b08.812e6eeb.png) bottom no-repeat;
    background-size: 100% 100%;
    height: 180px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0
}

.topsf .tois {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 20px;
    border-bottom: 1px solid #fff;
    margin: 0 auto;
    width: 90%;
    color: #fff
}

.topsf .tois .tisaa {
    font-size: 16px;
    font-weight: 700
}

.topsf .tois .newsa {
    margin-top: 6px;
    font-size: 10px
}

.topsf .tois img {
    width: 50px;
    margin-right: 15px
}

.bosfs {
    border-radius: 15px;
    padding: 4px 12px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 90%;
    margin: 15px auto 0;
    background-size: 100% 100%;
    -webkit-box-shadow: 0 .04rem .2rem 0 rgba(93,114,162,.11);
    box-shadow: 0 .04rem .2rem 0 rgba(93,114,162,.11)
}

.bosfs .hgsw {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-bottom: 1px solid #f5f0f0
}

.bosfs .hgsw .firsimg {
    width: 24px
}

.bosfs .hgsw .tit {
    color: #4e6693;
    margin-left: 10px
}

.bosfs .hgsw .tisf {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0 12px;
    color: #a2aec8;
    text-align: right
}

.bosfs .hgsw .rigiong {
    width: 6px
}

.topsf {
    background: url(/static/image/welcome-bg.812e6eebb547ed38a04db1056d489b08.812e6eeb.png) bottom no-repeat;
    background-size: 100% 100%;
    height: 180px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0
}

.topsf .tois {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 20px;
    border-bottom: 1px solid #fff;
    margin: 0 auto;
    width: 90%;
    color: #fff
}

.topsf .tois .tisaa {
    font-size: 16px;
    font-weight: 700
}

.topsf .tois .newsa {
    margin-top: 6px;
    font-size: 10px
}

.topsf .tois img {
    width: 50px;
    margin-right: 15px
}

.bosfs {
    border-radius: 15px;
    padding: 4px 12px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 90%;
    margin: 15px auto 0;
    background-size: 100% 100%;
    -webkit-box-shadow: 0 .04rem .2rem 0 rgba(93,114,162,.11);
    box-shadow: 0 .04rem .2rem 0 rgba(93,114,162,.11)
}

.bosfs .hgsw {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-bottom: 1px solid #f5f0f0
}

.bosfs .hgsw .firsimg {
    width: 24px
}

.bosfs .hgsw .tit {
    color: #4e6693;
    margin-left: 10px
}

.bosfs .hgsw .tisf {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0 12px;
    color: #a2aec8;
    text-align: right
}

.bosfs .hgsw .rigiong {
    width: 6px
}


</style>
