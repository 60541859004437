<template>
    <van-tabbar
        :value="activeTab"
        active-color="#597ef7"
        inactive-color="#000"
        @change="onChange"
        style="border-top: 1px solid #d5d8e0;
           box-sizing: border-box;
           /*background-color: rgb(243, 246, 255);*/
           z-index: 200;"
    >
        <van-tabbar-item v-for="(item, index) in tabItems" :key="index">
            <template #icon="props">
                <img :src="props.active ? item.activeIcon : item.inactiveIcon" />
            </template>
            <span>{{ item.name }}</span>
        </van-tabbar-item>
    </van-tabbar>
</template>

<script>
export default {
    name: 'Foot',
    props: ['activeTab'],
    data() {
        return {
            tabItems: [
                {
                    name: '首页',
                    activeIcon: '/static/image/tabbar_icon1_select2.png',
                    inactiveIcon: '/static/image/tabbar_icon1_nor.png'
                },
                {
                    name: '优惠',
                    activeIcon: '/static/image/tabbar_icon2_select.png',
                    inactiveIcon: '/static/image/tabbar_icon2_nor.png'
                },
                {
                    name: '客服',
                    activeIcon: '/static/image/tabbar_icon3_select2.png',
                    inactiveIcon: '/static/image/tabbar_icon3_nor.png'
                },
                {
                    name: '赞助',
                    activeIcon: '/static/image/tabbar_icon4_select2.png',
                    inactiveIcon: '/static/image/tabbar_icon4_nor.png'
                },
                {
                    name: '我的',
                    activeIcon: '/static/image/tabbar_icon5_select2.png',
                    inactiveIcon: '/static/image/tabbar_icon5_nor.png'
                },
            ]
        }
    },
    methods: {
        onChange(index) {
            console.log('Clicked tab index:', index);
            console.log('Previous active tab:', this.activeTab);
            console.log('Tab names:', this.tabItems.map(item => item.name)[index]);
            this.$emit('change', index);
        }
    }
}
</script>

<style scoped>
.van-tabbar {
    height: 68px;
    box-sizing: border-box;
    background-color: rgb(243, 246, 255);
    z-index: 200;
}
.van-tabbar-item--active {
    color: #1989fa;
    background-color: rgb(243, 246, 255);
}
.van-tabbar-item__icon img {
    display: block;
    height: 34px;
}
</style>
