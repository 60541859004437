<template>
    <div>
        <van-nav-bar style="position: fixed; top: 0; left: 0; width: 100%; background-color: #edf2fd" title="活动详情" left-arrow @click-left="$router.back()" />

        <div class="pahsn">
            <img src="/static/image/__al__title01.7a3975958589d48b22c30b3b976a95fc.png" style="display: block; width: 80%; margin: 0 auto; padding-top: 30px;">
            <img src="/static/image/__al__person01.8b896040f87c2dfffa7e8de68ed19c42.png" style="display: block; width: 100%; margin: 0 auto;">
            <div class="zixun">
                <img src="/static/image/16044962635685155.png">
                <div class="cnets">
                    <div class="tos">合营部</div>
                    <div class="bos" @click="$parent.openKefu">立即咨询</div>
                </div>
                <div class="zusnb" @click="$parent.openKefu">咨询</div>
            </div>
            <div class="bsd">
                <form class="van-form">
                    <div class="van-cell van-field van-field--disabled">
                        <div class="van-cell__title van-field__label"><span>用户名</span></div>
                        <div class="van-cell__value van-field__value">
                            <div class="van-field__body">
                                <input type="text" disabled class="van-field__control" :value="$store.state.userInfo.username">
                            </div>
                        </div>
                    </div>
                    <div class="van-cell van-field van-field--disabled">
                        <div class="van-cell__title van-field__label"><span>真实姓名</span></div>
                        <div class="van-cell__value van-field__value">
                            <div class="van-field__body">
                                <input type="text" disabled class="van-field__control" :value="$store.state.userInfo.realname">
                            </div>
                        </div>
                    </div>
                    <div class="van-cell van-field">
                        <div class="van-cell__title van-field__label"><span>联系方式</span></div>
                        <div class="van-cell__value van-field__value">
                            <div class="van-field__body">
                                <input type="text" placeholder="请输入您的联系方式" class="van-field__control" v-model="formData.mobile">
                            </div>
                        </div>
                    </div>
                    <div class="van-cell van-field">
                        <div class="van-cell__title van-field__label"><span>申请理由</span></div>
                        <div class="van-cell__value van-field__value">
                            <div class="van-field__body">
                                <input type="text" placeholder="请输入申请说明" class="van-field__control" v-model="formData.apply_info">
                            </div>
                        </div>
                    </div>
                </form>
                <button @click="apply" class="van-button van-button--info van-button--normal van-button--block">
                    <div class="van-button__content">
                        <span class="van-button__text">加入我们</span>
                    </div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'JointVentureApp',
    data() {
        return {
            formData: {},
            show: false,
        };
    },
    created() {
        let that = this;
        console.log(that.$store.state.userInfo)
    },
    methods: {
        changShow() {
            this.show = !this.show;
        },
        apply() {
            let that = this;
            if (!that.formData.mobile || !/^1[3-9]\d{9}$/.test(that.formData.mobile)) {
                that.$parent.showTost(0, '请输入正确的手机号');
                return;
            }
            if (!that.formData.apply_info) {
                that.$parent.showTost(0, '请输入申请理由');
                return;
            }
            that.$parent.showLoading();
            that.$apiFun
                .post('/api/applyagentdo', that.formData)
                .then(res => {
                    if (res.code != 200) {
                        that.$parent.showTost(0, res.message);
                    }
                    if (res.code == 200) {
                        that.$parent.showTost(1, '申请成功');
                        // that.$router.back();
                    }
                    that.$parent.hideLoading();
                })
                .catch(res => {
                    that.$parent.hideLoading();
                });
        },
    },
    mounted() {
        let that = this;
    },
    updated() {
        let that = this;
    },
};

</script>

<style scoped>
.van-nav-bar {
    position: relative;
    z-index: 1;
    line-height: 22px;
    text-align: center;
    background-color: #fff !important;
    -webkit-user-select: none;
    user-select: none;
}
.van-field--disabled .van-field__label {
    color: #c8c9cc;
}
.van-cell {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    padding: 10px 16px;
    overflow: hidden;
    color: #323233;
    font-size: 12px;
    line-height: 24px;
    background-color: #fff;
}
.van-button:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background-color: #000;
    border: inherit;
    border-color: #000;
    border-radius: inherit;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0;
    content: " ";
}
.van-button--normal {
    padding: 0 15px;
    font-size: 12px;
}
.pahsn {
    background: url(/static/image/__al__background.2e29d452d69738493237414076a048d3.png) no-repeat;
    background-size: 100% 100%;
    margin-top: 40px;
    min-height: 100vh;
    width: 100%
}

.zixun {
    width: 88%;
    background: #fdfdfd;
    border-radius: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px;
    position: relative;
    margin: -96px auto 0;
    opacity: .9
}

.zixun img {
    width: 30px;
    margin-right: 10px
}

.zixun .cnets {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border-left: 1px solid #ccc;
    padding-left: 10px
}

.zixun .cnets .tos {
    font-size: 12px;
    color: #999
}

.zixun .cnets .bos {
    font-size: 14px;
    color: #1e1e1e
}

.zixun .zusnb {
    width: 60px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 12px;
    color: #fff;
    background-color: #597ef7;
    border-radius: 4px
}

.bsd {
    width: 90%;
    background: #fdfdfd;
    border-radius: 10px;
    padding: 20px 10px;
    margin: 10px auto 0
}


/* Add more styles as needed */
</style>
